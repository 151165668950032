.editWinRatioDrawer {
    display: flex;
    width: 500px;
    height: 900px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.editWinRatioTitleRow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    align-self: stretch;
}

.editWinRatioTitle {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.closeDrawer {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editWinRatioContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
}

.editWinRatioInputLabel {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.editWinRatioInputs {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.editWinRatioLimit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.checkboxLabel {
    color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.subText {
    color: #9C9C9C;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.editWinRatioButton {
margin-top: auto;
width: 100%;
}