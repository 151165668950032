.gamesLogsPage {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.gamesLogsTitleRow {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    /* justify-content: space-between; */
}

.gamesLogsBackButton {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
}

.gamesLogsTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;

    color: #000000;

    opacity: 0.9;
}

.gamePagesRow {
    display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
padding: 8px 16px;
gap: 16px;

width: 100%;
height: 48px;

/* background: #FFFFFF; */
/* border-radius: 8px; */
}

.pageNavigationButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    gap: 8px;
    isolation: isolate;
    
    width: 32px;
    height: 32px;
    
    background: #FFFFFF;
    border: 2px solid #EBEBEB;
    border-radius: 4px;
}

.selectedPageNum {
    color: #007AFF;
}