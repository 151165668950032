.winRatioLogsPage {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.winRatioLogsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.winRatioLogsTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;

    color: #000000;

    opacity: 0.9;
    display: flex;
    align-items: center;
    gap: 16px;
}

.logsBackButton {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
}
.winRatioLogsPagesRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;

    width: 100%;
    height: 48px;
}

.location-container {
    display: flex;
    gap: 10px;
    padding-right: 5px;
    justify-content: space-between;
}

.address-text {
    flex: 1;
    padding-bottom: 0;
    margin-bottom: 0px;
}

.tableErrorMsg {
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
justify-content: center;
align-items: center;
/* identical to box height */
padding: 12px 16px;
overflow-wrap: break-word;

color: red;
}