.manualWinPagesRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
  
    width: 100%;
    height: 48px;
  }
  
  .pageNavigationButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    gap: 8px;
    isolation: isolate;
  
    width: 32px;
    height: 32px;
  
    background: #ffffff;
    border: 2px solid #ebebeb;
    border-radius: 4px;
  }