.statePage {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.StatesData {
    /* box-sizing: border-box; */
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    white-space: nowrap;
    white-space: nowrap;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
}

.stateMoreOptions {
    /* margin-left: auto; */
    display: flex;
    /* align-content: flex-end; */
    justify-content: flex-end;
}

.overlayContainer {
    position: relative;
    top: -40px;
    right: 10px;
}

.statesHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.statesRight {
    display: flex;
    flex-direction: row;
}

.addState {
    padding: 5px;
}
.manageStates {
    padding: 5px;
}

.statesTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    align-items: center;

    color: #000000;

    opacity: 0.9;
    display: flex;
    align-items: center;
    gap: 10px;
}

.history-element {
    font-size: 12px;
    margin-bottom: 0px;
}

.addedByParent {
    position: relative;
    cursor: pointer;
}

.stateHistory{
    width: max-content;
    display: none;
    position: absolute;
    bottom: 30px;
    left: 0px;

    padding: 8px;
    background: #000000;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #FFFFFF;
}

.statesBackButton {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
}

.addedBy:hover + .stateHistory {
    display: block;
}