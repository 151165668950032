.profile:hover {
    cursor: pointer;
}

.profile {
    height: 44px;
    width: 44px;
}
.profileContainer{
    display:flex;
    align-items: center;
    gap: 1rem;
}
.profileName {
    display: flex;
    width: 68px;
    height: 35px;
    flex-direction: column;
    justify-content: center;
    color: #9C9C9C;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
