.filtersRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 1136px;
  /* height: 48px; */

  /* background: #FBFBFB; */
}

.filtersButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  gap: 8px;

  width: 120px;
  height: 48px;

  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.filtersIcon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
}

.filtersButtonText {
  width: 60px;
  height: 19px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #000000;

  opacity: 0.9;
}

.sort {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 14px 16px;
  gap: 8px;

  width: 125px;
  height: 48px;
  position: relative;

  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.sortIcon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
}

.sortText {
  width: 60px;
  height: 19px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #000000;

  opacity: 0.9;
}

.sortOptions {
  position: relative;
  /* top: 0px;
    right: 0px; */
}

.sortedLabel {
  height: 35px;
  background: #ffffff;
  border: 1px solid #5e99f7;
  border-radius: 8px;
  gap: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
}

.sortedLabelText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;

  color: #000000;

  opacity: 0.9;
}

.removeSort {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.filter {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 300px;
}

.filterField {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  gap: 8px;

  width: 300px;
  height: 48px;

  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.custom-datepicker {
  /* display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 16px;
    gap: 8px; */

  width: 300px;
  height: 48px;

  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.calendarFilter {
  height: 19px;
  background-color: #ffffff;
}

.filterPlaceHolder {
  width: 240px;
  height: 19px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #9c9c9c;

  opacity: 0.9;
}

.filterText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;

  width: 240px;
  height: 36px;
}

.filterLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #9c9c9c;

  opacity: 0.9;
}

.filterValue {
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #000000;

  opacity: 0.9;
}

.arrowBlock {
  display: flex;
  width: 20px;
  height: 20px;

  background: #ffffff;

  align-items: center;
  justify-content: center;
}

.currentFilterValue {
  height: 35px;
  width: max-content;
  background: #ffffff;
  border: 1px solid #5e99f7;
  border-radius: 8px;
  gap: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
}

.multiFilterDrawer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  /* position: relative; */
  width: 400px;
  /* height: 900px; */
  height: 100%;
  background: #fbfbfb;
}

.multiFilterTitleRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 352px;
  height: max-content;
}

.multiFilterTitle {
  width: 296px;
  height: max-content;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  color: #000000;

  opacity: 0.9;
}

.closeMultiFilterButton {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* pointer-events: none; */
}

.filterCheckboxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}

.filterCheckboxLabelCombo {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.applyFilterButton {
  margin-top: auto;
  /* padding-top: 24px;
  padding-bottom: 24px; */
  width: 100%;
}
