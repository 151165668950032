.manualWinResultsPage {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.manualWinResultsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.manualResType {
  color: #007aff;
  font-weight: bold;
}

.manualWinResultsTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;

  color: #000000;

  opacity: 0.9;
}

.right {
  display: flex;
  gap: 16px;
}

.lastUpdated {
  width: 166px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.lastUpdatedTimeText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #9c9c9c;
}

.lastUpdatedTimeValue {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #000000;
}

.refreshBtnParent {
  position: relative;
}

.triggerApiRefreshBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  gap: 8px;
  isolation: isolate;

  width: 48px;
  height: 48px;

  background: #ffffff;
  border: 2px solid #ebebeb;
  border-radius: 8px;
}

.refreshHoverText {
  width: max-content;
  display: none;
  position: absolute;
  bottom: 50px;
  right: 0px;

  padding: 8px;
  background: #000000;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: #ffffff;

  z-index: 2;
}

.triggerApiRefreshBtn:hover + .refreshHoverText {
  display: block;
}

.refreshBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  gap: 8px;
  isolation: isolate;

  width: 48px;
  height: 48px;

  background: #ffffff;
  border: 2px solid #ebebeb;
  border-radius: 8px;
}

.logsBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  gap: 8px;

  width: 120px;
  height: 48px;

  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.logsText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #000000;

  opacity: 0.9;
}
.manualWinPagesRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;

  width: 100%;
  height: 48px;
}

.pageNavigationButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  gap: 8px;
  isolation: isolate;

  width: 32px;
  height: 32px;

  background: #ffffff;
  border: 2px solid #ebebeb;
  border-radius: 4px;
}

.gameInactiveText {
  color: #e21e1e;
  background: rgba(226, 30, 30, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;

  width: 80px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  border-radius: 99px;
}

.winNumberContainer {
  display: flex;
  /* width: 200px; */
  gap: 10px;
  align-items: center;
}

.resultsText {
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 120px;
}

.tableErrorMsg {
  font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
justify-content: center;
align-items: center;
/* identical to box height */
padding: 12px 16px;
overflow-wrap: break-word;

color: red;
}
