.background {

    display: flex;
      justify-content: center;
      align-items: center;

    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: '..src\resources\sign-in-background.png', #FBFBFB;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 16px;

    width: 600px;

    background: #FBFBFB;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 8px 32px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
}

.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.04em;
    height: 74px;

    color: #000000;

    opacity: 0.9;
}