.balancePage {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 24px;
    /* border:2px solid yellow; */
}

.balanceTitleRow {
    display: flex;
    flex-direction: row;
}

.balanceTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;

    color: #000000;

    opacity: 0.9;
}
.balanceStatsButton {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 16px;
}

.balanceStats {
    display: flex;
    flex-direction: column;
    width: 144px;
    /* justify-content: left; */
}

.availableBalance {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    color: #000000;
    opacity: 0.9;
}

.statLabel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #9C9C9C;
    opacity: 0.9;
}

.searchBar{
    display: flex;
    flex-direction: column;
    height: 160px;
    width: fill;
    margin-bottom: 8px;
    /* border:2px solid #000000; */
}

.adminTable{
    width: fill;
    height: 329px;
    border:1px solid #EBEBEB;
    border-radius: 8px;
}
.adminBalance{
    color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
}
.adminBalance:hover{
    cursor: pointer;
}
.agentBalance{
color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
}
.agentBalance:hover{
    cursor: pointer;
}
.manageAmount{
display: flex;
flex-direction: column;
flex: 1 0 0;
color: #007AFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-decoration-line: underline;
}
.manageAmount:hover{
    cursor: pointer;
}
.manageAmountDrawer{
display: flex;
width: 400px;
height: 900px;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
background-color: #FBFBFB;
}
.manageAmountHeader{
color: #000;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.agentStatusText {
    /* margin-left: auto; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;

    width: 80px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    border-radius: 99px;
}
.agentStatusTextactive{
    /* position:relative; */
    color: #4EB365;
    background: rgba(78, 179, 101, 0.1);
}

.agentStatusTextinactive{
    /* position:relative; */
    color: #E21E1E;
    background: rgba(226, 30, 30, 0.1);
}

/*Drawer CSS*/
.statContainer{
    display: flex;
    padding: 10px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    gap:8px
}
.manageButton{
display: flex;
/* padding: 10px 16px; */
justify-content: center;
align-items: center;
gap: 8px;
flex: 1 0 0;
align-self: stretch;
border-radius: 8px;
/* border: 2px solid #007AFF; */
border: 1px solid #EBEBEB;
background: #FFF;
width: 172px;
height: 39px;
}
.drawerTop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

}

.closeButtonManageAmount{
    width: 40px;
    height: 40px;

    background: #FBFBFB;

    display: flex;
    align-items: center;
    justify-content: center;
}
.closeButtonManageAmount:hover{
    cursor: pointer;
}
.saveButton{
    width: 100%;
    margin-top: auto;
}