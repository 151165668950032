.gamesPage {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.gamesTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.gamesTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;

    color: #000000;

    opacity: 0.9;
}

.gameTitleRowRightPanel {
    display: flex;
    gap: 16px
}

.gamesUpdateTime {
    width: 166px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.gamesUpdateTimeText {
   font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;

color: #9C9C9C;
}

.gamesUpdateTimeValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    
    color: #000000;
}

.gamesAPIRefreshButtonHoverParent {
    position: relative;
}

.gamesAPIRefreshButton {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 0px;
gap: 8px;
isolation: isolate;

width: 48px;
height: 48px;

background: #FFFFFF;
border: 2px solid #EBEBEB;
border-radius: 8px;
}

.gamesAPIRefreshHoverText {
    width: max-content;
    display: none;
    position: absolute;
    bottom: 50px;
    right: 0px;

    padding: 8px;
    background: #000000;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #FFFFFF;

    z-index: 2;

}

.gamesAPIRefreshButton:hover + .gamesAPIRefreshHoverText {
    display: block;
}

.gamesRefreshButton {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 0px;
gap: 8px;
isolation: isolate;

width: 48px;
height: 48px;

background: #FFFFFF;
border: 2px solid #EBEBEB;
border-radius: 8px;
}

.gameLogsButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 16px;
    gap: 8px;
    
    width: 120px;
    height: 48px;
    
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
}

.gameLogsButtonText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    
    color: #000000;
    
    opacity: 0.9;
}

.gamePagesRow {
    display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
padding: 8px 16px;
gap: 16px;

width: 100%;
height: 48px;

/* background: #FFFFFF; */
/* border-radius: 8px; */
}

.pageNavigationButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    gap: 8px;
    isolation: isolate;
    
    width: 32px;
    height: 32px;
    
    background: #FFFFFF;
    border: 2px solid #EBEBEB;
    border-radius: 4px;
}

.selectedPageNum {
    color: #007AFF;
}

.gameStatusText {
    /* margin-left: auto; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;

    width: 80px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    border-radius: 99px;
}

.gameStatusTextActive {
    color: #4EB365;
    background: rgba(78, 179, 101, 0.1);
}

.gameStatusTextInactive {
    color: #E21E1E;
    background: rgba(226, 30, 30, 0.1);
}

.gameStatusCommentsParent {
    position: relative;
}

.redText {
    color: #E21E1E;
}

.gameStatusComments {
    width: max-content;
    display: none;
    position: absolute;
    bottom: 30px;
    left: 0px;

    padding: 8px;
    background: #000000;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #FFFFFF;

    
}

.gameStatusText:hover + .gameStatusComments {
    display: block;
}

.winNumbersHoverParent {
    position: relative;
}

.winNumbers {

}

.triggerApiHover {
    width: max-content;
    display: none;
    position: absolute;
    bottom: 25px;
    right: 0px;

    padding: 8px;
    background: #000000;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #FFFFFF;

    z-index: 2;
}

.winNumbers:hover+.triggerApiHover {
    display: block;
}

/* Edit Game closing time side drawer start */

.editGameClosingTime {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    width: 500px;
    background: #FBFBFB;
    min-height: 100%;
}

.editGameClosingTimeTitleRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    gap: 16px;
    width: 100%;
}

.editGameClosingTimeTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* width: 296px; */
    /* identical to box height */


    color: #000000;

    opacity: 0.9;
}

.closeEditGameClosingTimeButton {
    width: 40px;
    height: 40px;

    background: #FBFBFB;

    display: flex;
    align-items: center;
    justify-content: center;
}

.editGameClosingTimeDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.editGameClosingTimeDetailsText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #000000;

    opacity: 0.9;
}

.editGameClosingTimeDetailRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.editGameClosingTimeDetailKey {
    width: 175px;
    height: 17px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    
    color: #9C9C9C;
    
    opacity: 0.9;
    
}

.editGameClosingTimeDetailValue {
    /* width: 191px; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
/* identical to box height */


    color: #000000;

    opacity: 0.9;
}

.setNewClosingTimeText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    opacity: 0.9;
    padding-top: 24px;
}

.newClosingTimeInputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.applyForAllHouses {
    display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 8px;
}

.appltForAllHousesText {
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

color: #000000;

opacity: 0.9;
}

.applyForAllHousesDescription {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    color: #9C9C9C;

    opacity: 0.9;

}

.applyGameClosingTimeButton {
    margin-top: auto;
    width: 100%;
}

/* Edit Game closing time side drawer end */