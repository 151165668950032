.popup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 15px;
    /* min-width: 0; */
  
    background: #fbfbfb;
    border-radius: 16px;
  
    position: fixed;
    top: 33%;
    left: 33%;
    z-index: 1;
    backdrop-filter: opacity(0.5);
  
    width: 400px;
  }
  
  .signinpopup {
    width: 600px;
    top: 30%;
    left: 30%;
  }
  
  .popupTitleClose {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* justify-content: space-between; */
    padding: 0px;
    /* gap: 16px; */
  }
  
  .popupTitle {
    width: 100%;
    white-space: normal;
  
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  
    color: #000000;
  
    opacity: 0.9;
  }
  
  .popupCloseButton {
    /* min-width: 56px; */
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }
  
  .subheadingPopup {
    font-family: "Inter";
    font-weight: bold;
    font-style: normal;
    font-size: 16px;
    color: #000000;
    margin: 0;
  }
  
  .detailsPopup {
    display: flex;
    gap: 5px;
  }
  
  .gameNamePopup {
    margin: 0px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #999999;
  }
  
  .winNumberPopup {
    margin: 0px;
    width: 100px;
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
  }
  
  .rightArrowPopup {
    width: 15px;
    height: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    position: relative;
    top: 8px;
  }
  