.backButton {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
}