.editBatchDrawer {
    display: flex;
    width: 400px;
    height: 900px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background: #FBFBFB;
}

.drawerTitleRow {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    justify-content: space-between;
}

.drawerTitleText {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 0.9;
}

.closeButton {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.detailsHeading {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.gameDetails {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.gameDetailKey {
    color: #9C9C9C;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 153px;
    height: auto;
}

.gameDetailValue {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: auto;
}

.inputsGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
}

.applyAllRow {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-top: 8px;
    align-items: center;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.applyAllNote {
    color: #9C9C9C;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}