.HeaderRow{
    display: flex;
    gap: 16px;
    padding-bottom: 24px;
}
.backArrow{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
}
.headerTitle{
    color: #000;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 0.9;
}
.agentDetails{
    opacity: 0.9;
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: auto;
    margin-bottom: 5px;
}
.FilterBar{
    display: flex;
    padding-bottom: 24px;
}
.IPformat{
color: #007AFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
}
.IPformat:hover{
    cursor: pointer;
}