.sectionItem {
  width: 100%;
}
.sectionItemTitle {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}
.sectionItemContent {
  padding: 0px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-evenly;
  align-items: center;
}
