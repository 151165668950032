.page {
  width: 100%;
}

.leftNavBar {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 4px;
    width: 300px;
    height: 100%;
    /* Initial colors/ White */
    background: #FFFFFF;
    /* border-right: 1px solid #EBEBEB; */
    
}
.topNavBar.hamburger-open .leftNavBar {
  transform: translateX(-100%);
  transition: all 0.5s ease-in-out;
}

.topNavBar.hamburger-open .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  background: transparent;
}

.leftNavBar.leftNavBar-hidden {
  /* transform: translateX(-100%);
  display: none; */
  /* transition: all 0.5s ease-in; */
  animation: top 0.3s ease-in-out both;

}
@keyframes top{
  0%{
      transform: translateX(0%);
  }

  33%{
    transform: translateX(-25%);

  }

  66%{
    transform: translateX(-50%);

  }
  86%{
    transform: translateX(-75%);

  }

  100%{
    transform: translateX(-100%);
    display: none;
  }
}



.profileOptions {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    position: absolute;
    right: 10px;
    top: 82px;
    width: 200px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 8px 32px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.mainPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    background: #F5F5F5;

}

.pageDisplay {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pageTitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
    opacity: 0.9;
}

.pageTitleRight,
.pageTitleLeft {
  display: flex;
  gap: 16px;
  align-items: center;
}

.profileSection {
  position: absolute;
  right: 10px;
  top: 82px;
}

.content {
  padding: 24px;
  background: #f5f5f5;
  width: 100%;
  min-height: 100%;
  /* height: 100%; */
}
.qtDetails {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 224px;
  height: 26px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  padding: 0 0 30px 10px;
  z-index: 1000; /* Adjust the z-index based on your layout */
}
.qtImg {
  width: 39px;
  height: 41px;
  flex-shrink: 0;
}
.qtInfo {
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.qtInfo h2 {
  color: #131313;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.1px;
  opacity: 0.5;
  margin-bottom: 1px;
}
.qtInfo h3 {
  color: #3e3c3c;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.48px;
  opacity: var(--Number, 1);
  margin: 0;
}
.searchInput{
width: 100%;
}
.searchInput .floating-label-input label{
  color: #000000;
} 
.qtnav  .qtInfo{
  gap: unset;
}
.qtnav{
  position: static;
  display: flex;
  align-items: center;
  gap: 8px;
}


