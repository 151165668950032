.textInput {
    box-sizing: border-box;

        width: 100%;
        height: 47px;

        padding: 14px 16px;
        gap: 8px;

        background: #FFFFFF;
        border: 1px solid #EBEBEB;
        border-radius: 8px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        color: #000000;

        opacity: 0.9;
}

.textInput:disabled {
    background: #F9F9F9;
}

.error {
    border: 2px solid #E21E1E;
}
