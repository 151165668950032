.toastAlert {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 42px;

/* position: absolute; */
width: 500px;
height: fit-content;
/* left: 36px;
top: 65px; */

    background: #FBFBFB;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 8px 32px rgba(0, 0, 0, 0.08);
    border-radius: 16px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* display: flex;
    align-items: center; */
    
    color: #000000;
    
    opacity: 0.9;

}

.toastCloseButton {
    margin-left: auto;
    height: 17.58px;
    width: 17.58px;
}