.editBatchDrawer {
    display: flex;
    width: 400px;
    height: 900px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background: #FBFBFB;
}

.drawerTitleRow {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    justify-content: space-between;
}

.drawerTitleText {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 0.9;
}

.closeButton {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputsGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
}