.dataTable {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    border: 1px solid #EBEBEB;
    border-radius: 8px;
}

.dataRow {
    width: 100%;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
align-items: center;
/* identical to box height */
padding: 12px 16px;
overflow-wrap: break-word;

color: #000000;
background: #FFFFFF;
border-bottom: 1px solid #EBEBEB;
}

.dataTitleRow {
    font-weight: 600;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.dataLastRow {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}