.popup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    /* min-width: 0; */

    background: #FBFBFB;
    border-radius: 16px;

    position: fixed;
    top: 40%;
    left: 40%;
    z-index: 1;
    backdrop-filter: opacity(0.5);

    width: 400px;
}

.signinpopup {
    width: 600px;
    top: 30%;
    left: 30%;
}

.popupTitleClose {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* justify-content: space-between; */
    padding: 0px;
    /* gap: 16px; */
}

.popupTitle {
    width: 100%;
    white-space: normal;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    opacity: 0.9;
}

.popupCloseButton {
    /* min-width: 56px; */
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}