.InfoPage {
    display: flex;
    padding: 10px 14px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  .InfoPage h2 {
    color: #000;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 0.9;
  }
  .Btn {
    border-radius: 8px;
    border: 2px solid #ebebeb;
    background: #fff;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: var(--Number, 1);
    display: flex;
    width: 120px;
    height: 48px;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }
  .edit {
    display: flex;
    width: 166px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .quill{
      height: 100%;
  }
  .save {
    background: #007aff;
    color: #ffffff;
  }
  .afterEdit {
    display: flex;
    gap: 1rem;
  }
  .ql-container {
    height: 100%;
  }
  .cancelBtn,
  .saveBtn {
    margin-right: 10px;
    padding: 8px 12px;
    cursor: pointer;
  }
  .EditorContainer {
    height: 400px; 
    overflow-y: auto; 
  }
  
  .Editor {
    width: 100%;
    height: 150%;
  }

  .borderTableCell {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .highlightHeader {
    background-color: #d3d3d3;
  }