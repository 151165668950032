.profileOverlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    padding: 24px;

    position: relative;
    width: 400px;
    height: 100%;

    background: #FBFBFB;
}

.titleRow {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profileTitle {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */


    color: #000000;

    opacity: 0.9;
}

.closeButton {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeButton:hover {
    cursor: pointer;
}

.updateDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 33px 0px;
}

.textInputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* align-items: center; */
    width: 100%;
}

.profileMobileNumber {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.setPasswordLabel {
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 15px;
padding-top: 36px;
/* identical to box height */
color: #606060;
opacity: 0.9;
}

.profileSave {
    margin-top: auto;
    width: 100%;
}
.eye-icon {
    position: absolute;
    top: 18%;
    right: 1rem;
    cursor: pointer;
}
.password {
    position: relative;
}

  
