.cityPage {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.citiesData {
    /* box-sizing: border-box; */
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    white-space: nowrap;
    white-space: nowrap;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
}

.dataTitleRow {
    font-weight: 600;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.CityDataRow {
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    /* identical to box height */
    padding: 12px 16px;

    color: #000000;
    background: #FFFFFF;
    border-bottom: 1px solid #EBEBEB;
}

.cityMoreOptions {
    /* margin-left: auto; */
    display: flex;
    /* align-content: flex-end; */
    justify-content: flex-end;
}

.overlayContainer {
    position: relative;
    top: -40px;
    right: 10px;
}

.citiesHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.citiesRight {
    display: flex;
    flex-direction: row;
}

.addCity {
    padding: 5px;
}
.manageStates {
    padding: 5px;
}

.citiesTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;

    color: #000000;

    opacity: 0.9;
}

.city-history {
    background-color: #111111;
    opacity: 0.9;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
}

.history-element {
    font-size: 12px;
    margin-bottom: 0px;
}

.addedByParent {
    position: relative;
    cursor: pointer;
}

.cityHistory{
    width: max-content;
    display: none;
    position: absolute;
    bottom: 30px;
    left: 0px;

    padding: 8px;
    background: #000000;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #FFFFFF;
}

.addedBy:hover + .cityHistory {
    display: block;
}